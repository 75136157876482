import { API_URL } from "../../config";
import { http } from "../http";
import { AllPropsNullable } from "./AllPropsNullable.type";

type getPrizesResponse = AllPropsNullable<{
  prize1Flag1: number;
  prize1Flag2: number;
  prize1Line1: string;
  prize1Line2: string;
  prize2Flag1: number;
  prize2Flag2: number;
  prize2Line1: string;
  prize2Line2: string;
  prize3Flag1: number;
  prize3Flag2: number;
  prize3Line1: string;
  prize3Line2: string;
  prize4Flag1: number;
  prize4Flag2: number;
  prize4Line1: string;
  prize4Line2: string;
  prize5Flag1: number;
  prize5Flag2: number;
  prize5Line1: string;
  prize5Line2: string;
  prize6Flag1: number;
  prize6Flag2: number;
  prize6Line1: string;
  prize6Line2: string;
  prize7Flag1: number;
  prize7Flag2: number;
  prize7Line1: string;
  prize7Line2: string;
  prize8Flag1: number;
  prize8Flag2: number;
  prize8Line1: string;
  prize8Line2: string;
  prize9Flag1: number;
  prize9Flag2: number;
  prize9Line1: string;
  prize9Line2: string;
  prize10Flag1: number;
  prize10Flag2: number;
  prize10Line1: string;
  prize10Line2: string;
}>;

export async function getPrizes(id: string) {
  const { parsedBody } = await http<getPrizesResponse>(
    new Request(`${API_URL}/game/prizes/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }),
  );

  return parsedBody;
}