import { adjustHue, darken } from "color2k";
import StyleConverter from "../../helpers/StyleConverter";
import useCSSVariableUpdate from "../../hooks/useCSSVariableUpdate";
import { useStoreData } from "../../store/data.context";

export function PrizeSubTitle({value}: {value: string}) {
  const { state } = useStoreData();
  const { fontSize, color, ...restStyles } = state?.settings?.prizeLine2?.style || {};

  useCSSVariableUpdate({ key: '--prize-subtitle-size', value: fontSize });
  useCSSVariableUpdate({ key: '--prize-subtitle-color', value: color });
  useCSSVariableUpdate({ key: '--prize-subtitle-color-dark', value: color ? darken(adjustHue(color, 360 - 16), 0.15) : undefined});
  useCSSVariableUpdate({ key: '--prize-subtitle-font-family', value: restStyles?.fontFamily });


  return <div className="prize-subtitle" style={StyleConverter(restStyles)}>
    <div className="prize-subtitle__shadow" style={restStyles?.fontFamily ? {fontFamily: restStyles.fontFamily} : undefined}>
      {value}
    </div>
    {value}
  </div>
}