import party from 'party-js';
import { getPrizes } from '../../helpers/types/getPrizes';
import { useStoreData } from '../../store/data.context';
import {
  FancyAnimationActionType,
  useFancyAnimation,
} from '../../store/FancyAnimation.context';

type runPrizeAnimationProps = {
  ref: React.RefObject<HTMLElement>;
  id: string;
};

const usePrizeAnimation = ({ ref, id }: runPrizeAnimationProps) => {
  const { dispatch: fancyAnimationDispatch } = useFancyAnimation();
  const { dispatch } = useStoreData();

  const runAnimation = async () => {
    fancyAnimationDispatch({
      type: FancyAnimationActionType.RESET_FANCY_ANIMATION_STATE,
    });
    
    const prizesResponse = await getPrizes(id);
    const title = prizesResponse?.prize1Line1;
    const subtitle = prizesResponse?.prize1Line2 || '';
    
    if (!ref?.current || !title) return;

    const cardItemCoords = ref.current.getBoundingClientRect();

    dispatch((previousStateData) => {
      if (!previousStateData) return previousStateData;

      return {
        ...previousStateData,
        game: {
          ...previousStateData.game,
          prize1Line1: title,
          prize1Line2: subtitle,
        },
      };
    });

    fancyAnimationDispatch({
      type: FancyAnimationActionType.SET_COORDS,
      payload: {
        x: cardItemCoords.x + cardItemCoords.width / 2,
        y: cardItemCoords.y + cardItemCoords.height / 2,
      },
    });
    fancyAnimationDispatch({
      type: FancyAnimationActionType.SET_TITLE,
      payload: title,
    });
    fancyAnimationDispatch({
      type: FancyAnimationActionType.SET_SUBTITLE,
      payload: subtitle,
    });
    fancyAnimationDispatch({
      type: FancyAnimationActionType.SET_ANIMATION_ACTIVE,
      payload: true,
    });

    party.sparkles(ref.current, {
      count: party.variation.range(20, 30),
    });
  };

  return runAnimation;
};

export default usePrizeAnimation;
