import { ISettingsData, useStoreData } from '../store/data.context';
import { Cards } from '../components/cards/cards';
import { CasinoName } from '../components/casino-name/casino-name';
import { Header } from '../components/header/header';
import { PopUp } from '../components/popup/popup';
import { Spacer } from '../components/spacer/spacer';
import { UserName } from '../components/username/username';
import { url } from '../helpers/url';
import { useEffect } from 'react';

export function GamePreview() {
  const { state, dispatch } = useStoreData();

  useEffect(() => {
    const messageHandler = (event: MessageEvent<ISettingsData>) => {
      dispatch({
        settings: event.data,
        game: {
          casinoName: 'Casino Name',
          disclaimer1: 'Redeem at any promotional kiosk.',
          disclaimer2: 'Offer expires 05/04/2021',
          disclaimer3: '',
          firstName: 'John',
          gameExpireDate: new Date(2099, 12, 12),
          lastName: 'Doe',
          played: 0,
          playerId: '000',
          prize1Line1: "25$",
          prize1Line2: "FreePlay"
        },
      });
    };
    window.addEventListener('message', messageHandler, false);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [dispatch]);

  if (!state) {
    return null;
  }

  const {settings, game} = state;

  return (
    <>
     <style>
      {settings.fonts}
    </style>
      <div className="game" style={{backgroundImage: `url(${settings?.background?.file && url(settings?.background?.file)})`}}>
        {!!settings?.header1?.file && <Header imageSource={settings.header1.file} />}
        {!!settings?.header2?.file && <Header imageSource={settings.header2.file} />}
          <UserName value={`${game.firstName} ${game.lastName}`} playerId={game.playerId} style={settings.userName.style}/>
        <Spacer />
        <Cards id="0000" />
        <CasinoName />
        <PopUp
          prize1Line1={game.prize1Line1}
          prize1Line2={game.prize1Line2}
          background={settings?.popup?.file}
          congratsLine1={settings?.congratsLine1?.file}
          congratsLine2={settings?.congratsLine2?.file}
        />
      </div>
    </>
  );
}
