import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { FancyAnimationProvider } from './store/FancyAnimation.context';
import FancyAnimation from './components/fancy-animation/FancyAnimation';

const tagManagerArgs = {
  // TODO: configure .env variable and replace hard coded key with an empty string
  gtmId: process.env.GOOGLE_TAG_MANAGER_KEY || 'GTM-NRN6834',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <FancyAnimationProvider>
        <App />
        <FancyAnimation />
      </FancyAnimationProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
