import { useData, useStoreData } from "../store/data.context";
import { Cards } from '../components/cards/cards';
import { CasinoName } from '../components/casino-name/casino-name';
import { Header } from '../components/header/header';
import { PopUp } from '../components/popup/popup';
import { Spacer } from '../components/spacer/spacer';
import { UserName } from '../components/username/username';
import { Page404 } from '../components/page-404/page404';
import { url } from '../helpers/url';
import { useLocation } from 'react-router-dom';

export function SimpleGame() {
  const location = useLocation();
  const id = location.pathname.split('/')[1];
  const {state, dispatch} = useStoreData();
  const [error] = useData(id, dispatch);

  if (error) {
    return <Page404 />
  }

  if (!state) {
    return null;
  }

  const {settings, game} = state;

  console.log(state);

  return <>
    <style>
      {settings.fonts}
    </style>
      <div className="game" style={{backgroundImage: `url(${settings?.background?.file && url(settings?.background?.file)})`}}>
        {!!settings?.header1?.file && <Header imageSource={settings.header1.file} />}
        {!!settings?.header2?.file && <Header imageSource={settings.header2.file} />}
          <UserName value={`${game.firstName} ${game.lastName}`} playerId={game.playerId} style={settings.userName.style}/>
        <Spacer />
        <Cards id={id} />
        <CasinoName />
        <PopUp
          prize1Line1={game.prize1Line1}
          prize1Line2={game.prize1Line2}
          background={settings?.popup?.file}
          congratsLine1={settings?.congratsLine1?.file}
          congratsLine2={settings?.congratsLine2?.file}
        />
      </div>
    </>
}