import { createContext, useContext, useState } from "react";

interface IModalContext {
  state: boolean;
  dispatch: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalContext = createContext<null | IModalContext>(null);



export const  ModalProvider: React.FC = (props) => {
  const [state, dispatch] = useState(false);
  const { children } = props;
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export const useModal = () => {
  const store = useContext(ModalContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
};
