import './popup.css';
import { HeaderPopup } from './header.popup';
import { HeaderTitlePopup } from './header-title.popup';
import { PrizeTitle } from './prize-title.popup';
import { PrizeSubTitle } from './prize-subtitle.popup';
import { Info } from './info-1.popup';
import { useModal } from '../../store/modal.context';
import { url } from '../../helpers/url';
import { useStoreData } from '../../store/data.context';
import { useEffect } from 'react';

interface IPopUpProps {
  prize1Line1: string;
  prize1Line2: string;
  background: string | undefined;
  congratsLine1: string | undefined;
  congratsLine2: string | undefined;
}

export function PopUp(props: IPopUpProps) {
  const { state, dispatch } = useModal();
  const { state: stateData } = useStoreData();

  useEffect(() => {
    const showModalOnInit = () => {
      const params = new URLSearchParams(document.location.search.substring(1));
      const shouldOpenModal = !!params.get('modal');

      if (shouldOpenModal) {
        dispatch(shouldOpenModal);
      }
    };

    setTimeout(() => {
      showModalOnInit();
    }, 1000);
  }, [dispatch]);

  return (
    <div
      className={`pop-up ${state ? 'pop-up--is-open' : ''}`}
      style={{
        backgroundImage: `url(${props?.background && url(props?.background)})`,
      }}>
      {props?.congratsLine1 && (
        <HeaderPopup imageSource={props.congratsLine1} />
      )}
      {props?.congratsLine2 && (
        <HeaderTitlePopup imageSource={props.congratsLine2} />
      )}
      <div className="pop-up__text-wrapper">
        <PrizeTitle value={props.prize1Line1} />
        <PrizeSubTitle value={props.prize1Line2} />
        <Info
          style={stateData?.settings.disclaimer1.style}
          value={`${stateData?.game.firstName} ${stateData?.game.lastName} (#${stateData?.game.playerId})`}
        />
        <Info
          style={stateData?.settings.disclaimer1.style}
          value={`${stateData?.game.disclaimer1}`}
        />
        <Info
          style={stateData?.settings.disclaimer2.style}
          value={`${stateData?.game.disclaimer2}`}
        />
        <Info
          style={stateData?.settings.disclaimer3.style}
          value={`${stateData?.game.disclaimer3}`}
        />
      </div>
    </div>
  );
}
