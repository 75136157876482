import { createContext, useContext, useState, useEffect } from "react";
import { API_URL } from '../config';

// interface IModalContext {
//   state: boolean;
//   dispatch: React.Dispatch<React.SetStateAction<boolean>>
// }

interface IStyledValue {
  style: React.CSSProperties;
  file?: string;
}

type TFileValue = {
  file?: string;
} | undefined

export interface ISettingsData {
  casinoName: IStyledValue;
  background: IStyledValue;
  userName: IStyledValue;
  popup: IStyledValue;
  fonts: string;
  gameCard: {
    gameCard1: TFileValue;
    gameCard2: TFileValue;
    gameCard3: TFileValue;
    gameCard4: TFileValue;
    gameCard5: TFileValue;
    gameCard6: TFileValue;
  };
  header1: TFileValue;
  header2: TFileValue;
  prizeLine1: IStyledValue;
  prizeLine2: IStyledValue;
  congratsLine1: TFileValue;
  congratsLine2: TFileValue;
  disclaimer1: IStyledValue;
  disclaimer2: IStyledValue;
  disclaimer3: IStyledValue;
}

type TGameData = {
  playerId: string;
  casinoName: string;
  played: number;
  firstName: string;
  lastName: string;
  prize1Line1: string;
  prize1Line2: string;
  disclaimer1: string;
  disclaimer2: string;
  disclaimer3: string;
  gameExpireDate: Date;
};

interface IData {
  settings: ISettingsData,
  game: TGameData,
}

interface IDataContext {
  state: IData | null;
  dispatch: React.Dispatch<React.SetStateAction<IData | null>>
}

const DataContext = createContext<null | IDataContext>(null);

export const DataProvider: React.FC = (props) => {
  const [state, dispatch] = useState<IData | null>(null);
  const { children } = props;
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch};
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

 export function useData(id:string, setData: (val: IData) => void) {
  const [error, setError] = useState(null);

  useEffect(() => {
    function getData() {
      fetch(`${API_URL}/game/${id}`)
        .then(response => response.json())
        .then(data => {
          const { gameSettings, ...gameData } = data;
          setData({ settings: gameSettings.settings, game: gameData });
        }).catch(e => {
          setError(e);
          console.log(e)
        });
    }
    getData();
  }, [id, setData])
  
  
  return [error];
}

export const useStoreData= () => {
  const store = useContext(DataContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
};
