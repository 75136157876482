import React, { createContext, useContext, useEffect } from 'react';

export enum FancyAnimationActionType {
  SET_ANIMATION_ACTIVE = 'SET_ANIMATION',
  SET_TITLE = 'SET_TITLE',
  SET_SUBTITLE = 'SET_SUBTITLE',
  SET_RESET_TIMEOUT = 'SET_RESET_TIMEOUT',
  SET_COORDS = 'SET_COORDS',
  RESET_FANCY_ANIMATION_STATE = 'RESET_FANCY_ANIMATION_STATE',
}

type AnimationState = {
  animationActive: boolean;
  title: string;
  subtitle: string;
  resetTimeout: number;
  coords: {
    x: number;
    y: number;
  };
};

const INITIAL_STATE: AnimationState = {
  animationActive: false,
  title: '',
  subtitle: '',
  resetTimeout: 3000,
  coords: {
    x: 0,
    y: 0,
  },
};

type FancyAnimationContextType = {
  state: AnimationState | null;
  dispatch: React.Dispatch<FancyAnimationAction>;
};

const FancyAnimationContext =
  createContext<null | FancyAnimationContextType>(null);

export type FancyAnimationAction =
  | { type: FancyAnimationActionType.SET_ANIMATION_ACTIVE; payload: boolean }
  | { type: FancyAnimationActionType.SET_TITLE; payload: string }
  | { type: FancyAnimationActionType.SET_SUBTITLE; payload: string }
  | { type: FancyAnimationActionType.SET_RESET_TIMEOUT; payload: number }
  | {
      type: FancyAnimationActionType.SET_COORDS;
      payload: {
        x: number;
        y: number;
      };
    }
  | { type: FancyAnimationActionType.RESET_FANCY_ANIMATION_STATE };

function animationReducer(
  state: AnimationState,
  action: FancyAnimationAction,
): AnimationState {
  switch (action.type) {
    case FancyAnimationActionType.SET_ANIMATION_ACTIVE: {
      return {
        ...state,
        animationActive: action.payload,
      };
    }
    case FancyAnimationActionType.SET_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case FancyAnimationActionType.SET_SUBTITLE: {
      return {
        ...state,
        subtitle: action.payload,
      };
    }
    case FancyAnimationActionType.SET_RESET_TIMEOUT: {
      return {
        ...state,
        resetTimeout: action.payload,
      };
    }
    case FancyAnimationActionType.SET_COORDS: {
      return {
        ...state,
        coords: action.payload,
      };
    }
    case FancyAnimationActionType.RESET_FANCY_ANIMATION_STATE: {
      return INITIAL_STATE;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export const FancyAnimationProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(animationReducer, INITIAL_STATE);
  const { children } = props;
  const value = { state, dispatch };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (state.animationActive) {
      timeout = setTimeout(() => {
        dispatch({type: FancyAnimationActionType.RESET_FANCY_ANIMATION_STATE});
      }, 3000);
    }

    return () => {
      if(timeout) {
        clearTimeout(timeout);
      }
    }
  }, [state.animationActive]);

  return (
    <FancyAnimationContext.Provider value={value}>
      {children}
    </FancyAnimationContext.Provider>
  );
};

export const useFancyAnimation = () => {
  const store = useContext(FancyAnimationContext);
  
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
};
