import './App.css';

import { Switch, Route } from 'react-router-dom';

import { ModalProvider } from './store/modal.context';
import { DataProvider } from './store/data.context';
import { SimpleGame } from './pages/SimpleGame';
import { GamePreview } from './pages/GamePreview';

function App() {
  return (
    <DataProvider>
      <ModalProvider>
        <div className="app">
          <Switch>
            <Route exact path="/game/preview">
              <GamePreview />
            </Route>
            <Route path="/:id">
              <SimpleGame />
            </Route>
          </Switch>
        </div>
      </ModalProvider>
    </DataProvider>
  );
}

export default App;
