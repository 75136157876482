import { CSSProperties } from 'react';
import StyleConverter from '../../helpers/StyleConverter';
import './style.css';

type ErrorMessageProps = {
  style?: CSSProperties;
}

export const ErrorMessage:React.FC<ErrorMessageProps> = ({ children, style }) => {
  return <div className="error-message" style={StyleConverter(style)}>{children}</div>;
}