import { useEffect } from "react"

type useCSSVariableUpdateProps = {
  key: string | undefined;
  value: string | number | undefined | null;
}
const useCSSVariableUpdate = ({ key, value }: useCSSVariableUpdateProps) => {
  useEffect(() => {
    if(value && key) {
      document.documentElement.style.setProperty(key, String(value))
    }
  },[value, key])
}

export default useCSSVariableUpdate
