import { CSSProperties } from "react";

/**
 * This function wraps fontFamily with whitespace with quotes.
 * @param style - JavaScript CSS object
 */
const StyleConverter = (style: CSSProperties | undefined) => {
  if(!style) return style;

  if(style?.fontFamily && style.fontFamily.includes(' ')) {
    return {...style, fontFamily: `'${style.fontFamily}'`}
  }

  return style;
} 

export default StyleConverter;