import { useFancyAnimation } from '../../store/FancyAnimation.context';
import './FancyAnimation.css';

const FancyAnimation = () => {
  const { state } = useFancyAnimation();

  if (!state) {
    return null;
  }

  return (
    <div className="fancy-animation__wrapper">
      <div
        style={{
          top: state.coords.y,
          left: state.coords.x,
        }}
        className={`fancy-animation ${
          state.animationActive ? 'fancy-animation--is-open' : ''
        }`}>
        <div className="fancy-animation__animation">
          <div className="fancy-animation__title">{state.title}</div>
          <div className="fancy-animation__subtitle">{state.subtitle}</div>
        </div>
      </div>
    </div>
  );
};

export default FancyAnimation;
