import './cards.style.css';
import { useModal } from '../../store/modal.context';
import { API_URL } from '../../config';
import React, { memo } from 'react';
import { useStoreData } from '../../store/data.context';
import { ErrorMessage } from '../error-messages/error-message';
import { url } from '../../helpers/url';
import usePrizeAnimation from './usePrizeAnimation';

interface iCardItem {
  onClick: () => void;
  cardImage: string;
  id: string;
}

interface iCardsProps {
  id: string;
}

export const CardItem = memo(({ onClick, cardImage, id }: iCardItem) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const runPrizeAnimation = usePrizeAnimation({
    ref,
    id,
  });

  const onTap = async () => {
    runPrizeAnimation();

    setTimeout(() => {
      onClick();
    }, 1000);
  };

  return (
    <div ref={ref} className="card-item" onClick={onTap}>
      <img className="card-item__img" src={url(cardImage)} alt="card" />
    </div>
  );
});

export function Cards(props: iCardsProps) {
  const { id } = props;
  const { dispatch } = useModal();
  const { state } = useStoreData();

  const showModal = () => {
    if (!state) return;

    const show = async () => {
      dispatch(true);
      const response = await fetch(`${API_URL}/game/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      await response.json();
      dispatch(true);
    };

    show();
  };

  if (state?.game.played === 1) {
    return (
      <ErrorMessage style={state.settings?.userName?.style}>
        You have already redeemed this offer.
      </ErrorMessage>
    );
  }

  if (
    state?.game.gameExpireDate &&
    new Date(state?.game.gameExpireDate).getTime() < new Date().getTime()
  ) {
    return (
      <ErrorMessage style={state.settings?.userName?.style}>
        This offer has expired.
      </ErrorMessage>
    );
  }

  if (!state?.settings?.gameCard) return null;

  return (
    <div className="cards">
      {Object.values(state.settings.gameCard).map((value, index) => {
        if (!value?.file) return <div />;

        return (
          <CardItem
            id={id}
            key={index}
            onClick={showModal}
            cardImage={value.file}
          />
        );
      })}
    </div>
  );
}
