import { CSSProperties } from 'react';
import StyleConverter from '../../helpers/StyleConverter';

type InfoProps = {
  value: string;
  style?: CSSProperties;
};

export function Info({ value, style }: InfoProps) {
  const { fontSize, ...restStyles } = style || {};
  const fontSizeStyle = fontSize
    ? { '--info-size': fontSize }
    : ({} as React.CSSProperties);

  return (
    <div
      className="pop-up-info"
      style={{ ...StyleConverter(restStyles), ...fontSizeStyle }}>
      {value}
    </div>
  );
}
