import StyleConverter from '../../helpers/StyleConverter';
import { useStoreData } from '../../store/data.context';
import './casino-name.css';

export const CasinoName = () => {
  const {state} = useStoreData();
  
  return (
    <div className="casino-name" style={StyleConverter(state?.settings.casinoName.style)}>{state?.game.casinoName}</div>
  )
}
